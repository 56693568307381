import { BrowserWindow, DynamicSessionModel, Experiments, PlatformBootstrapData, PlatformEnvData, ViewerModel } from '@wix/thunderbolt-symbols'
import _ from 'lodash'
import { BootstrapData } from '../types'
import { StorageInitData } from '../storage/storage'
import { FallbackStrategy, getFallbackOverrideStrategy, SiteAssetsClientConfig } from 'thunderbolt-site-assets-client'
import { getCSRFToken } from '@wix/thunderbolt-commons'

export function createBootstrapData({
	platformBootstrapData,
	viewerModel,
	experiments,
	currentContextId,
	currentPageId,
	storageInitData,
	commonConfig,
	applicationsInstances,
	siteAssetsClientConfig,
	window,
	platformEnvData
}: {
	platformBootstrapData: PlatformBootstrapData
	viewerModel: ViewerModel
	experiments: Experiments
	currentContextId: string
	currentPageId: string
	commonConfig: ViewerModel['commonConfig']
	storageInitData: StorageInitData
	applicationsInstances: DynamicSessionModel['apps']
	siteAssetsClientConfig: SiteAssetsClientConfig
	window: BrowserWindow
	platformEnvData: PlatformEnvData
}): BootstrapData {
	const {
		siteFeaturesConfigs,
		siteAssets,
		deviceInfo,
		site: { externalBaseUrl, siteRevision, isResponsive },
		mode
	} = viewerModel
	const sdkFactoriesSiteFeatureConfigs = _.pickBy(siteFeaturesConfigs, (siteConfig, featureName) => featureName.toLowerCase().includes('wixcodesdk'))
	const siteAssetsClientInitParams = {
		...siteAssets,
		deviceInfo,
		siteAssetsClientConfig,
		qaMode: mode.qa,
		fallbackStrategy:
			getFallbackOverrideStrategy(experiments, 'clientWorker') || ((experiments['specs.thunderbolt.shouldEnableSACFallbackForClientWorker'] ? 'enable' : 'disable') as FallbackStrategy)
	}
	const csrfToken = getCSRFToken(window)

	return {
		isResponsive,
		externalBaseUrl,
		csrfToken,
		siteRevision,
		currentPageId,
		currentContextId,
		siteAssetsClientInitParams,
		experiments,
		applicationsInstances,
		mode,
		commonConfig,
		storageInitData,
		platformEnvData,
		sdkFactoriesSiteFeatureConfigs,
		...platformBootstrapData
	}
}
