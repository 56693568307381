// Load `core-js` on older browsers that are missing key features
if (
	typeof Promise === 'undefined' ||
	typeof Set === 'undefined' ||
	typeof Object.assign === 'undefined' ||
	typeof Array.from === 'undefined' ||
	typeof Symbol === 'undefined'
) {
	// @ts-ignore
	importScripts(require('./utils/constants').CORE_JS_BUNDLE_URL)
}
// @ts-ignore
importScripts('https://static.parastorage.com/unpkg/lodash@4.17.15/lodash.min.js')
require('thunderbolt-platform/src/client/clientWorker')
