
  var rt = require("@stylable/runtime/cjs/css-runtime-stylesheet.js");

  module.exports = rt.create(
      "Captcha3233060386",
      {"classes":{"root":"Captcha3233060386__root","captcha":"Captcha3233060386__captcha","checkbox":"Captcha3233060386__checkbox"},"vars":{},"stVars":{},"keyframes":{}},
      "",
      -1,
      module.id
  );
  