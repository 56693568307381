import { withDependencies, named } from '@wix/thunderbolt-ioc'
import { SdkHandlersProvider, SiteFeatureConfigSymbol } from '@wix/thunderbolt-symbols'
import { INavigation, NavigationSymbol } from 'feature-navigation'
import { IUrlHistoryManager, UrlHistoryManagerSymbol } from 'feature-router'
import { name } from '../symbols'

export const locationWixCodeSdkHandlersProvider = withDependencies(
	[NavigationSymbol, UrlHistoryManagerSymbol, named(SiteFeatureConfigSymbol, name)],
	(navigation: INavigation, urlHistoryManager: IUrlHistoryManager): SdkHandlersProvider<Record<string, Function>> => {
		return {
			getSdkHandlers: () => ({
				navigateTo: navigation.navigateTo,
				pushUrlState: (href: string): void => {
					const url = new URL(href)
					urlHistoryManager.pushUrlState(url)
				},
			}),
		}
	}
)
