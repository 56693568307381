import { WixCodeApiFactoryArgs } from '@wix/thunderbolt-symbols'
import { namespace, DashboardWixCodeSdkHandlers, DashboardWixCodeSdkWixCodeApi } from '..'
import { dashboardApiFacadeFactory } from './services/dashboardApiFacadeFactory'
import { callDashboardApiFactory } from './services/callDashboardApiFactory'

/**
 * This is SDK Factory.
 * Expose your Corvid API
 */
export function DashboardSdkFactory({
	handlers,
}: WixCodeApiFactoryArgs<{}, {}, DashboardWixCodeSdkHandlers>): { [namespace]: DashboardWixCodeSdkWixCodeApi } {
	const { getDashboardApi } = handlers
	const callDashboardApi = callDashboardApiFactory(getDashboardApi)

	return {
		[namespace]: dashboardApiFacadeFactory(callDashboardApi),
	}
}
