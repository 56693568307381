import { PolicyDetails, PolicyHeaderObject } from '@wix/cookie-consent-policy-client'
import { createFedopsLogger } from '@wix/thunderbolt-commons'
import { WixCodeApiFactoryArgs } from '@wix/thunderbolt-symbols'
import { MemberDetails, memberDetailsFromDTO, SiteMembersSiteConfig } from 'feature-site-members'
import {
	SiteMembersWixCodeSdkHandlers,
	SiteMembersWixCodeSdkWixCodeApi,
	REGISTRATION_RESULT_STATUS_DISPLAY,
	LoginHandler,
	ConsentPolicyChangedHandler,
} from '../types'
import { namespace } from '../symbols'
import { User } from '../user/user'
import { apis } from '../user/utils'
import { validateEmailUserParams } from './validations'

export async function SiteMembersSdkFactory({
	featureConfig,
	handlers,
	platformEnvData,
	platformUtils,
}: WixCodeApiFactoryArgs<SiteMembersSiteConfig, {}, SiteMembersWixCodeSdkHandlers>): Promise<{
	[namespace]: SiteMembersWixCodeSdkWixCodeApi
}> {
	const { smToken, smcollectionId } = featureConfig

	const isUserLoggedIn = !!smToken
	let { details: consentPolicyDetails, header: consentPolicyHeaderObject } = platformEnvData.consentPolicy
	const {
		login,
		applySessionToken,
		promptForgotPassword,
		promptLogin,
		register,
		registerToUserLogin,
		logout,
		getMemberDetails,
		handleOauthToken,
		setConsentPolicy,
		resetConsentPolicy,
		registerToConsentPolicyChanges,
	}: SiteMembersWixCodeSdkHandlers = handlers
	const { sessionService, biUtils } = platformUtils
	const {
		window: { isSSR },
		bi: biData,
		location: { externalBaseUrl: baseUrl },
	} = platformEnvData
	const { svSession, msId } = biData

	const getMemberDetailsUrl = new URL(
		`/_api/wix-sm-webapp/member/${smToken}?collectionId=${smcollectionId}&metaSiteId=${msId}`,
		platformEnvData.url.href
	).href

	const getMemberDetailsForSSR: () => Promise<MemberDetails | null> = () =>
		isUserLoggedIn
			? self
					.fetch(getMemberDetailsUrl, {
						headers: {
							'x-wix-client-artifact-id': 'thunderbolt',
						},
					})
					.then((r) => r.json())
					.then((r) => (r.errorCode ? null : memberDetailsFromDTO(r.payload)))
			: Promise.resolve(null)

	const memberDetails = isSSR ? await getMemberDetailsForSSR() : await getMemberDetails()
	const currentUser = new User(
		{ ...memberDetails, uid: memberDetails?.id, svSession },
		memberDetails ? REGISTRATION_RESULT_STATUS_DISPLAY[memberDetails.status] : undefined,
		baseUrl,
		sessionService.getWixCodeInstance()
	)
	let onLogin: Array<LoginHandler> = []

	const clonePolicyDetails = (policyDetails: PolicyDetails) => ({
		...policyDetails,
		policy: {
			...policyDetails.policy,
		},
	})

	const clonePolicyHeaderObject = (policyHeaderObject: PolicyHeaderObject) => ({
		...policyHeaderObject,
	})

	const consentPolicyChangedHandlers: Array<ConsentPolicyChangedHandler> = []
	const sendUserEmailApi = apis.sendUserEmailApi(baseUrl)
	const fedopsLogger = createFedopsLogger({
		appName: 'site-members-wix-code-sdk',
		biLoggerFactory: biUtils.createBiLoggerFactoryForFedops(biData),
		phasesConfig: 'SEND_START_AND_FINISH',
		customParams: {
			viewerName: 'thunderbolt',
		},
	})

	const api: SiteMembersWixCodeSdkWixCodeApi = {
		currentUser,
		async login(email, password) {
			await login(email, password)
		},
		applySessionToken,
		async emailUser(emailId, toUser, options) {
			fedopsLogger.interactionStarted('email-user')
			let processedOptions
			try {
				processedOptions = validateEmailUserParams(emailId, toUser, options).processedOptions
			} catch (err) {
				fedopsLogger.interactionEnded('email-user')
				throw err
			}
			const params = { emailId, contactId: toUser, options: processedOptions }
			const response = await fetch(sendUserEmailApi, {
				method: 'POST',
				headers: { authorization: sessionService.getWixCodeInstance() || '' },
				body: JSON.stringify(params),
			})
			if (!response.ok) {
				throw new Error(await response.text())
			}
			fedopsLogger.interactionEnded('email-user')
		},
		promptForgotPassword,
		async promptLogin(options) {
			await promptLogin(options)
			// We can count on currentUser being updated because login is guaranteed not to
			// resolve before all onLogin callbacks (including the one that updates currentMember)
			// have resolved.
			return api.currentUser
		},
		async register(email, password, options = {}) {
			try {
				const data = await register(email, password, options)
				return {
					status: data.status,
					...(data.approvalToken ? { approvalToken: data.approvalToken } : {}),
					user: new User(
						{
							uid: data.user?.id,
							svSession,
							...data.user,
						},
						REGISTRATION_RESULT_STATUS_DISPLAY[data.status],
						baseUrl,
						sessionService.getWixCodeInstance()
					),
				}
			} catch (error) {
				if (error.message) {
					console.error(error.message)
					return Promise.reject(error.message)
				}
			}
		},
		onLogin(handler: LoginHandler) {
			onLogin = [...onLogin, handler]
		},
		logout,
		async handleOauthToken(token: string, provider: string, mode: string, joinCommunityStatus: string) {
			await handleOauthToken(token, provider, mode, joinCommunityStatus)
		},
		getCurrentConsentPolicy: () => {
			return clonePolicyDetails(consentPolicyDetails)
		},
		_getConsentPolicyHeader: () => {
			return clonePolicyHeaderObject(consentPolicyHeaderObject)
		},
		setConsentPolicy,
		resetConsentPolicy,
		onConsentPolicyChanged: (handler: ConsentPolicyChangedHandler) => {
			consentPolicyChangedHandlers.push(handler)
		},
		supportsPopupAutoClose: true,
	}

	if (process.env.browser) {
		registerToConsentPolicyChanges((policyDetails: PolicyDetails, policyHeaderObject: PolicyHeaderObject) => {
			consentPolicyDetails = policyDetails
			consentPolicyHeaderObject = policyHeaderObject
			consentPolicyChangedHandlers.forEach((handler) => handler(clonePolicyDetails(policyDetails)))
		})

		registerToUserLogin(async () => {
			const newMemberDetails = await getMemberDetails()
			api.currentUser = new User(
				{ ...newMemberDetails, uid: newMemberDetails?.id, svSession },
				newMemberDetails ? REGISTRATION_RESULT_STATUS_DISPLAY[newMemberDetails.status] : undefined,
				baseUrl,
				sessionService.getWixCodeInstance()
			)
			onLogin.forEach((handler) => {
				try {
					handler(api.currentUser)
				} catch (e) {
					console.error(e)
				}
			})
		})
	}

	return {
		[namespace]: api,
	}
}
