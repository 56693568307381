import { named, withDependencies } from '@wix/thunderbolt-ioc'
import {
	SiteFeatureConfigSymbol,
	PlatformEnvDataProvider,
	SiteWixCodeSdkSiteConfig,
	ExperimentsSymbol,
	Experiments,
	PlatformEnvData,
} from '@wix/thunderbolt-symbols'
import { name } from '../symbols'

export const siteEnvDataProvider = withDependencies(
	[ExperimentsSymbol, named(SiteFeatureConfigSymbol, name)],
	(experiments: Experiments, { pageIdToTitle }: SiteWixCodeSdkSiteConfig): PlatformEnvDataProvider => {
		const site: PlatformEnvData['site'] = {
			experiments,
			pageIdToTitle,
		}

		return {
			platformEnvData: { site },
		}
	}
)
