import { named, withDependencies } from '@wix/thunderbolt-ioc'
import { PageFeatureConfigSymbol, pageIdSym, SiteFeatureConfigSymbol } from '@wix/thunderbolt-symbols'
import {
	BuildTpaSrcOptions,
	ITpaSrcBuilder,
	name as tpaCommonsName,
	TpaCommonsSiteConfig,
	TpaSrcBuilderSymbol,
} from 'feature-tpa-commons'
import { name } from './symbols'
import { ITpaComponentApi, TpaPageConfig } from './types'
import * as ResponsiveChatUtils from './utils/responsiveChatUtils'

export const TpaComponentApi = withDependencies(
	[
		named(SiteFeatureConfigSymbol, tpaCommonsName),
		named(PageFeatureConfigSymbol, name),
		pageIdSym,
		TpaSrcBuilderSymbol,
	],
	(
		{ widgetsClientSpecMapData, isMobileView, viewMode, externalBaseUrl }: TpaCommonsSiteConfig,
		{ widgets, tpaInnerRouteConfig }: TpaPageConfig,
		pageId: string,
		tpaSrcBuilder: ITpaSrcBuilder
	): ITpaComponentApi => ({
		buildSrc(compId) {
			const tpaCompData = widgets[compId]
			const templateOrUniqueId = ResponsiveChatUtils.getTemplateOrUniqueId(compId, tpaCompData)
			const { widgetId, isSection } = tpaCompData

			const { widgetUrl, mobileUrl } = widgetsClientSpecMapData[widgetId]

			const baseUrl = isMobileView ? mobileUrl || widgetUrl : widgetUrl

			// If the component is a responsive chat - change the page id to masterPage, to be consistent on every navigation
			const overridePageId = ResponsiveChatUtils.isResponsiveChat(tpaCompData) ? 'masterPage' : pageId

			const options: Partial<BuildTpaSrcOptions> = { extraQueryParams: {} }
			if (isSection) {
				options.tpaInnerRouteConfig = tpaInnerRouteConfig
				if (viewMode === 'site') {
					options.extraQueryParams!['section-url'] = `${externalBaseUrl}/${tpaInnerRouteConfig.tpaPageUri}/`
					options.extraQueryParams!.target = '_top'
				} else {
					options.extraQueryParams!['section-url'] = baseUrl
					options.extraQueryParams!.target = '_self'
				}
			}
			return tpaSrcBuilder.buildSrc(templateOrUniqueId, overridePageId, tpaCompData, baseUrl, options)
		},
	})
)
