import * as React from 'react';
import { ICaptchaProps, ICaptchaImperativeActions } from '../Captcha.types';
import { useRecaptcha } from '../../../providers/useRecaptcha/useRecaptcha';
import { st, classes } from './style/Captcha.st.css';

const Captcha: React.ForwardRefRenderFunction<
  ICaptchaImperativeActions,
  ICaptchaProps
> = (props, ref) => {
  const {
    id,
    language,
    token,
    isMobileFriendly = true,
    onTokenChange = () => {},
    onMouseEnter = () => {},
    onMouseLeave = () => {},
    onError = () => {},
    onTimeout = () => {},
    onVerified = () => {},
  } = props;

  const [loaded, setLoaded] = React.useState<boolean>(false);

  const verifiedCallback = (_token: string) => {
    onTokenChange(_token);
    onVerified({ type: 'onVerified' });
  };

  const expiredCallback = () => {
    onTokenChange(undefined);
    onTimeout({ type: 'onTimeout' });
  };

  const errorCallback = () => {
    onError({ type: 'onError' });
  };

  const loadedCallback = () => {
    setLoaded(true);
  };

  const [captchaRef, { reset: resetCaptcha }] = useRecaptcha({
    language,
    theme: 'light',
    size: 'normal',
    verifiedCallback,
    expiredCallback,
    errorCallback,
    loadedCallback,
  });

  React.useImperativeHandle(
    ref,
    () => {
      return {
        focus: () => {
          const iframe = document.querySelector(
            `#${id} iframe`,
          ) as HTMLIFrameElement;
          if (iframe) {
            iframe.setAttribute('title', 'Captcha');
            iframe.focus();
          }
        },
        reset: () => {
          resetCaptcha();
        },
      };
    },
    [id, resetCaptcha],
  );

  return (
    <div
      id={id}
      className={st(classes.root, { loaded, isMobileFriendly })}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      <div className={classes.captcha} ref={captchaRef}></div>
      <input
        className={classes.checkbox}
        type="checkbox"
        checked={!!token}
        required
        onChange={() => {}}
        tabIndex={-1}
      />
    </div>
  );
};

export default React.forwardRef(Captcha);
