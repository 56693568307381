import _ from 'lodash'
import { getDecodedUrlObject } from '@wix/thunderbolt-commons'
import { LocationManager, PlatformEnvData, LocationOnChangeHandler } from '@wix/thunderbolt-symbols'
import { BootstrapData, PlatformUrlManagerSdkHandlers } from '../types'

export function CreateLocationManager({
	handlers,
	platformEnvData,
	bootstrapData
}: {
	handlers: PlatformUrlManagerSdkHandlers
	platformEnvData: PlatformEnvData
	bootstrapData: BootstrapData
}): LocationManager {
	let url = getDecodedUrlObject(platformEnvData.location.rawUrl)

	const onChangeHandlers: Array<LocationOnChangeHandler> = []

	if (process.env.browser) {
		handlers.registerLocationOnChangeHandler((href: string) => {
			url = getDecodedUrlObject(href)
			onChangeHandlers.forEach((handler) => handler({ path: getPath() }))
		})
	}

	const getFullPath = () => {
		const isFreeSite = platformEnvData.location.domain === 'wixsite.com'
		const pathAsArray = url.pathname.substring(1).split('/')
		return isFreeSite ? pathAsArray.slice(1) : pathAsArray
	}

	const getPath = () => {
		const fullPath = getFullPath()
		return prefix && fullPath[0] === prefix ? fullPath.slice(1) : fullPath
	}

	const routerData = getFullPath()[0] && _.find(bootstrapData.platformAPIData.routersConfigMap, { prefix: getFullPath()[0] })
	const prefix = routerData && routerData.prefix

	return {
		getLocation() {
			return url
		},
		getSearchParams() {
			const params: Record<string, Array<string> | string> = {}
			url.searchParams.forEach((value, key) => {
				const values = url.searchParams.getAll(key)
				params[key] = values.length > 1 ? values : value
			})
			return params
		},
		setSearchParam(key, value) {
			url.searchParams.set(key, value)
		},
		deleteSearchParam(key) {
			url.searchParams.delete(key)
		},
		onChange(handler) {
			onChangeHandlers.push(handler)
		},
		getPath,
		getPrefix() {
			return prefix
		}
	}
}
