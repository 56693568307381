import classNamesFn from 'classnames';
import { FreemiumBannerDesktopProps as FreemiumBannerDesktopTBProps } from '@wix/thunderbolt-components';
import * as React from 'react';
import { DefaultCompPlatformProps } from '@wix/editor-elements-types';
import { WixLogo } from '../../common/assets/logos';
import Link from '../../../Link/viewer/Link';
import { TestIds } from '../../common/constants';
import * as translations from './constants';
import style from './style/FreemiumBannerDesktop.scss';

const defaultDirection = 'ltr';

export type FreemiumBannerDesktopProps = DefaultCompPlatformProps &
  FreemiumBannerDesktopTBProps;

const FreemiumBannerDesktop: React.FC<FreemiumBannerDesktopProps> = props => {
  const {
    id = 'WIX_ADS',
    translate,
    useOverlay = false,
    direction,
    href = '',
    classNames = [defaultDirection],
  } = props;

  const anchorClassNames = classNamesFn(
    ...classNames.map(name => style[name]),
    style.desktopTop,
  );

  const translatedBannerText =
    translate!(
      translations.NAMESPACE,
      translations.MAIN_TEXT_KEY,
      translations.MAIN_TEXT_DEFAULT,
    ) || '';
  const [textBeforeLogo, textAfterLogo] = translatedBannerText.split(
    translations.wixLogoPlaceHolder,
  );
  const buttonText = translate!(
    translations.NAMESPACE,
    translations.BUTTON_TEXT_KEY,
    translations.BUTTON_TEXT_DEFAULT,
  );

  return (
    <div id={id} className={`${style.desktop} ${style.freemiumBanner}`}>
      {useOverlay ? (
        <div data-testid={TestIds.overlay} className={anchorClassNames} />
      ) : (
        <Link
          className={anchorClassNames}
          href={href}
          target="_blank"
          rel="nofollow"
        >
          <span className={style.contents}>
            <span className={style.text}>
              {textBeforeLogo}
              <div style={{ direction: 'ltr', display: 'inline-flex' }}>
                <div>
                  <WixLogo rootClass={style.wixLogo} dotClass={style.dot} />
                </div>
                <div className={style.com}>.com</div>
              </div>
              {textAfterLogo}
            </span>
            <span className={`${style.button} ${style[direction]}`}>
              {buttonText}
            </span>
          </span>
        </Link>
      )}
    </div>
  );
};

export default FreemiumBannerDesktop;
