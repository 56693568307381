//@ts-check
import config from '../utils/config.js';
import {rejector, max, closestId} from '../utils/utils.js';
import promisifyObserver from '../utils/promisifyObserver.js';

export const entryType = 'largest-contentful-paint';

/**
 * @typedef {Object} LargestContentfulPaintType
 * @property {number} startTime
 * @property {number} size
 * @property {string} url
 * @property {string} id
 * @property {Element} [element]
 * 
 * @typedef {LargestContentfulPaintType & PerformanceEntry} LargestContentfulPaint
 * 
 * @typedef {Object} LargestContentfulPaintResult
 * @property {number} [lcp]
 * @property {number} [lcpSize]
 * @property {string} [closestId]
 * @property {string} [lcpTag]
 */

/**
 * Get LCP attributes
 * @param {import('../utils/utils.js').State} state
 * @param {Promise<{fcp: number}>} paints 
 * @param {Promise<{tti: number}>} interactive
 */
export default function lcp([, performance, PerformanceObserver, setTimeout, clearTimeout], paints, interactive, visibility) {
    let lcpFound;
    return Promise.all([paints, interactive])
        .then(([{fcp}, {tti}]) => {
            const {lcpMin, downloadToRenderDelta, resourceDebounce} = config;
            const limit = max(tti, fcp + lcpMin);
            
            let timer;
            const timeout = new Promise(resolve => {
                timer = setTimeout(resolve, resourceDebounce);
            });

            return Promise.race([timeout, visibility, promisifyObserver(PerformanceObserver, entryType, (entries, resolve) => {
                const lcpCandidate = /** @type {Array<LargestContentfulPaint>} */ (entries).reverse().find(({url, startTime}) => {
                    if (startTime < limit) {
                        return true;
                    }
                    if (url) {
                        const resource = /** @type {PerformanceResourceTiming} */(performance.getEntriesByName(url)[0]);
                        if (resource) {
                            const {initiatorType, startTime: st, duration} = resource;
                            if (st < limit && startTime - (st + duration) < downloadToRenderDelta && initiatorType !== 'link') {
                                return true;
                            }
                        }
                    }
                    return false;
                });

                if (lcpCandidate) {
                    lcpFound = lcpCandidate;
                    clearTimeout(timer);
                    timer = setTimeout(resolve, resourceDebounce);
                }
            })]);
        })
        .then(() => lcpResult(lcpFound))
        .catch(rejector(entryType));
}

/**
 * Calculate LCP result from measurement
 * @param {LargestContentfulPaint} [lcp]
 * @returns {LargestContentfulPaintResult}
 */
export function lcpResult(lcp) {
    if (!lcp) {
        return;
    }
    const {startTime, size, id, element} = lcp;
    const result = {
        lcp: startTime,
        lcpSize: size
    };
    const cid = closestId(element, id);
    if (cid) {
        result.closestId = cid;
    }
    if (element) {
        const {tagName} = element;
        if (tagName) {
            result.lcpTag = tagName;
        }
    }
    return result;
}
